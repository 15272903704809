import React from "react"

import Layout from "../components/layout"
import Wrapper from "../components/wrapper"
import SEO from "../components/seo"
import styles from "../styles/menu-embed.module.scss"
import Button from "../components/button"
import { FaExternalLinkSquareAlt } from "react-icons/fa"
// import { VscFilePdf } from "react-icons/vsc"

const DiningMenu = ({ pageContext }) => {
  const { slug, embed: embedURI, link: linkURI, name: title } = pageContext
  console.dir(`${unescape(embedURI)}`)

  return (
    <Layout className={styles.menu}>
      <SEO title="Dining Menu" />
      <Wrapper sizeReadable>
        <h2>{title}</h2>
        <figure className="menu-embed">
          <iframe title={title} src={embedURI}></iframe>
        </figure>
        <Button
          href={linkURI}
          target="_blank"
          rel="noopener"
          style={{ border: "none", marginRight: "1rem" }}
        >
          Open in a new tab
          <FaExternalLinkSquareAlt className="icon icon-external">
            External
          </FaExternalLinkSquareAlt>
        </Button>
        {/* <Button */}
        {/*   href="/brothers-menu.pdf" */}
        {/*   style={{border: 'none'}} */}
        {/* > */}
        {/*   Open as PDF */}
        {/*   <VscFilePdf className="icon icon-pdf"></VscFilePdf> */}
        {/* </Button> */}
      </Wrapper>
    </Layout>
  )
}

export default DiningMenu
